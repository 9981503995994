import React from "react";
import { motion } from "framer-motion";
const Skill = ({ directionLeft, imageSrc }) => {
  return (
    <div className="group relative flex cursor-pointer">
      <motion.img
        initial={{
          x: directionLeft ? -60 : 60,
          opacity: 0,
        }}
        transition={{ duration: 0.8 }}
        whileInView={{ opacity: 1, x: 0 }}
        className="rounded-full border border-gray-500 object-cover w-24 h-24 xl:w-32 xl:h-32 filter group-hover:grayscale transition duration-300 ease-in-out md:h-28 md:w-28"
        src={imageSrc}
      />
      <div className="absolute opacity-0 group-hover:opacity-80 duration-300 transition ease-in-out group-hover:bg-white h-24 w-24 md:h-28 md:w-28 xl:w-32 xl:h-32 rounded-full z-0">
        <div className="flex items-center justify-center h-full">
          <p className="text-3xl font-bold text-black opacity-100%">100%</p>
        </div>
      </div>
    </div>
  );
};

export default Skill;
